import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import ErrorOverlay from "../../components/ErrorOverlay";

import {
  asyncGetCurrentRide,
  cancelRide,
  updateRideDetails,
  updateCurrentRideActions,
  setRideDetails,
} from "../../redux/slices/rideSlice";
import socket from "../../services/socket";
import { notifySuccess } from "../../components/toast";
import { asyncGetWallet } from "../../redux/slices/walletSlice";
import RatingModal from "../../components/modals/ratingModal";

function PassengerLayout({ children }) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { userInfo } = auth;
  const location = useSelector((state) => state.location);

  // useEffect(() => {
  //   socketInstance = io(SOCKET_BASE_URL);

  //   // setSocketConnected(true);
  //   socketInstance.on("connect", () => {
  //     dispatch(socketConnected(socketInstance));
  //     // dispatch({
  //     //   type: SOCKET_CONNECTED,
  //     //   payload: { socketInstance, socketConnected: true },
  //     // });
  //     // console.log()

  //     socketInstance.on("error", (error) => {
  //       console.log(error);
  //     });
  //     socketInstance.emit("passenger-connected", auth.userInfo._id);
  //   });

  //   // usersShiftsData()

  //   return () => {
  //     socketInstance.disconnect();
  //     dispatch(socketDisconnected());

  //     // dispatch({
  //     //   type: SOCKET_DISCONNECT,
  //     //   payload: { socketInstance: null, socketConnected: false },
  //     // });
  //     // socket.off("login");
  //   };
  // }, []);

  useEffect(() => {
    dispatch(asyncGetCurrentRide());
  }, []);

  const connectSocket = () => {
    try {
      if (socket?.connect && !socket.connected && navigator?.onLine) {
        socket.connect();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   // console.log("socket connected==>", socket.connected);
  //   if (socket.conected) {
  //     socket.emit("driver-connected", auth.userInfo._id);
  //   }
  // }, [socket]);

  useEffect(() => {
    if (userInfo) {
      dispatch(asyncGetWallet());

      connectSocket();
      socket.on("connect", () => {
        console.log("socket connected");
        socket.emit("passenger-connected", auth.userInfo._id);
        // socket.on("new-ride-request", (data) => {
        //   console.log("new ride request==>", data);
        //   notificationSound.play();
        //   setNewRideRequest(true);
        //   setRideRequestDetails(data);
        // });
      });

      socket.on("ride-booked", (data) => {
        console.log("ride accepted==>", data);
        dispatch(setRideDetails(data));
        notifySuccess("Ride booked successfully!");
      });

      socket.on("ride-action-status-v2", (data) => {
        console.log("ride action==>", data);
        // dispatch(setRideDetails(data));
        dispatch(updateRideDetails(data.ride));
        // dispatch(updateCurrentRideActions(data));
        notifySuccess(data.message);
      });

      socket.on("ride-cancelled", (data) => {
        console.log("ride cancelled==>", data);
        dispatch(cancelRide());
        notifySuccess("Ride cancelled by driver!");
      });

      // socket.on("ride-request-timeout", (data) => {
      //   console.log("ride req timeout==>", data);

      //   dispatch(
      //     addRideRequest({ ...rideRequestDetails, status: "Request Timeout" })
      //   );
      //   setRideRequestDetails(null);
      //   setNewRideRequest(false);
      //   notifySuccess("Ride request timeout!");
      // });

      socket.on("disconnect", () => {
        console.log("socket disconnected");
        socket.emit("passenger-disconnected", auth.userInfo._id);
        connectSocket();
      });

      // const intervalFunction = () => {
      //   // Your code here
      //   console.log("This runs every 5 seconds");

      //   // socket.emit("update-driver-location");
      // };

      // Use setInterval to call the function every 5 seconds (5000 milliseconds)
      // const intervalId = setInterval(intervalFunction, 5000);
      return () => {
        // clearInterval(intervalId);

        socket.emit("passenger-disconnected", auth.userInfo._id);
        socket.off("ride-cancelled");
        socket.off("ride-booked");
        socket.off("ride-action-status-v2");
        socket.disconnect();
      };
    }
  }, [userInfo]);

  // useEffect(() => {
  //   dispatch(asyncGetWallet());
  // }, []);

  // const location = useLocation();
  useEffect(() => {
    // Select the element with the class name 'paraxdo-header-section'
    const element = document.querySelector(".paraxdo-header-section");

    // Scroll to the element if it exists
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [location.pathname]);

  if (!auth.userInfo) {
    return <Navigate to="/login" />;
  }
  return (
    <div className="main-container">
      <Header />
      <div className="content-area">
        <div className="container">
          {/* <FullScreenLoader /> */}
          <div className="dash-content my-5">{children}</div>
        </div>
      </div>
      <Footer />

      {/* <RatingModal show={true} /> */}
      {!location.hasLocationPermission && (
        <ErrorOverlay errorMessage="Please allow location to this site and reload or close the tab and open again!" />
      )}
    </div>
  );
}

export default PassengerLayout;
