import axios from "axios";
import tokenService from "./tokenService";
import { BASE_URL } from "../constants";

// Development
export const axiosPublic = axios.create({
  baseURL: `${BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
    accepts: "application/json",
  },
});

const axiosPrivate = axios.create({
  baseURL: `${BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
    accepts: "application/json",

    Authorization: `Bearer ${tokenService.getLocalAccessToken()}`,
  },
});

export const axiosNoAuth = axios.create({
  baseURL: `${BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
    accepts: "application/json",

    Authorization: `Bearer ${tokenService.getLocalAccessToken()}`,
  },
});

//Production
// export const axiosPublic = axios.create({
//   baseURL: `/api`,
// });

// const axiosPrivate = axios.create({
//   baseURL: `/api`,
//   headers: {
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${tokenService.getLocalAccessToken()}`,
//   },
// });

// export const axiosNoAuth = axios.create({
//   baseURL: `${BASE_URL}/api`,
//   headers: {
//     "Content-Type": "application/json",
//     accepts: "application/json",

//     Authorization: `Bearer ${tokenService.getLocalAccessToken()}`,
//   },
// });

// Request interceptor for API calls

axiosPrivate.interceptors.request.use((req) => {
  const bearerInReq = req.headers.Authorization;

  if (bearerInReq.includes("undefined")) {
    req.headers["Authorization"] =
      "Bearer " + tokenService.getLocalAccessToken();
  }

  // req.meta = req.meta || {};
  // req.meta.requestStartedAt = new Date().getTime();
  return req;
});

// Response interceptor for API calls
axiosPrivate.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const { data } = await axiosPublic.post(`/passengers/refresh-tokens`, {
          refreshToken: tokenService.getLocalRefreshToken(),
        });

        // dispatch(refreshToken(accessToken));

        tokenService.updateLocalAccessToken(data);
        originalRequest.headers["Authorization"] = "Bearer " + data.token;

        return axiosPrivate(originalRequest);
        // return axiosApiInstance(originalRequest);
      } catch (err) {
        console.log("err in refresh token=>", err);
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

axiosNoAuth.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const { data } = await axiosPrivate.post(`/auth/refresh-tokens`, {
          refreshToken: tokenService.getLocalRefreshToken(),
        });

        // dispatch(refreshToken(accessToken));

        originalRequest.headers["Authorization"] = "Bearer " + data.token;
        // tokenService.updateLocalAccessToken(data);
        localStorage.removeItem("tokens");

        return axiosPrivate(originalRequest);
        // return axiosApiInstance(originalRequest);
      } catch (err) {
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosPrivate;
