import React, { useEffect, useRef, useState } from "react";
import PlacesSearch from "../../../components/googleMapComponents/PlacesSearch";
import MapComponent from "../../../components/googleMapComponents/mapComponent";
import { useDispatch, useSelector } from "react-redux";
import FullScreenLoader from "../../../components/loading/FullScreenLoader";
import CustomButton from "../../../components/CustomButton";
import { notifySuccess, notifyFailure } from "../../../components/toast";
import { asyncGetVehicleCategories } from "../../../redux/slices/vehicleCategorySlice";
import axiosPrivate from "../../../services/api";
import { Navigate } from "react-router-dom";
import socket from "../../../services/socket";

function NoRideView() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const rides = useSelector((state) => state.rides);
  const { currentRide } = rides;

  const vehicleCategories = useSelector((state) => state.vehicleCategories);
  const wallet = useSelector((state) => state.wallet);

  // const socket = useSelector((state) => state.socket);
  const location = useSelector((state) => state.location);
  const setting = useSelector((state) => state.settings);
  const { data } = setting;
  const { walletSettings } = data;
  console.log(walletSettings, "walletSettingsNoRide");

  const pickupRef = useRef();
  const dropoffRef = useRef();

  const [loading, setLoading] = useState(false);
  const [pickup, setPickup] = useState(null);
  const [dropoff, setDropoff] = useState(null);
  const [vehicleCategory, setVehicleCategory] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("Cash");

  console.log("ride details==>", pickup, dropoff);
  const [distance, setDistance] = useState(null);
  const [duration, setDuration] = useState(null);
  const [fareDetails, setFareDetails] = useState(null);
  const [directions, setDirections] = useState(null);

  const submitHandler = (e) => {
    e.preventDefault();

    if (
      paymentMethod === "Wallet" &&
      parseFloat(wallet.data.amount) <
        parseFloat(walletSettings.minimumAmountToBookRide)
    ) {
      notifyFailure(
        `Your don't have enough amount in wallet, the minimum required amount is ${auth?.userInfo?.country?.currency} ${walletSettings?.minimumAmountToBookRide}`
      );
      return;
    }

    setLoading(true);

    socket.emit(
      "book-ride-v2",
      {
        pickup,
        dropoff,
        wayPoints: [pickup, dropoff],
        vehicleCategory,
        fareDetails,
        distance,
        duration,
        paymentMethod,
        passenger: {
          _id: auth.userInfo._id,
          firstName: auth.userInfo.firstName,
          lastName: auth.userInfo.lastName,
          phone: auth.userInfo.phone,
        },
      },
      (res) => {
        console.log("book ride res=>", res);
      }
    );
  };
  // const rideStatusHandler = (rideStatus) => {
  //   console.log("callback chala", rideStatus);
  // };

  // console.log("ride==>", {
  //   pickup,
  //   dropoff,
  //   vehicleCategory,
  //   fareDetails,
  //   distance,
  //   duration,
  //   paymentMethod,
  //   passenger: {
  //     id: auth.userInfo._id,
  //     firstName: auth.userInfo.firstName,
  //     lastName: auth.userInfo.lastName,
  //     phone: auth.userInfo.phone,
  //   },
  // });
  console.log("book-ride=>", {
    pickup,
    dropoff,
    wayPoints: [pickup, dropoff],
    vehicleCategory,
    fareDetails,
    distance,
    duration,
    paymentMethod,
    passenger: {
      id: auth.userInfo._id,
      firstName: auth.userInfo.firstName,
      lastName: auth.userInfo.lastName,
      phone: auth.userInfo.phone,
    },
  });
  useEffect(() => {
    if (socket) {
      socket.on("booking-status", (bookingStatus) => {
        if (bookingStatus.success) {
          notifySuccess(bookingStatus.message);
        } else {
          setLoading(false);
          notifyFailure(bookingStatus.message);
        }
        console.log("booking status", bookingStatus);
      });
    }
    return () => {
      socket.off("booking-status");
    };
  }, [socket]);

  useEffect(() => {
    dispatch(asyncGetVehicleCategories());
  }, []);

  useEffect(() => {
    const calculateFare = async () => {
      try {
        const { data } = await axiosPrivate.post(
          "/rideFares/calculateRideFare",
          {
            vehicleCategory,
            distanceTraveled: parseFloat(distance.split(" ")[0]),
            minutesWaited: 10,
          }
        );
        // console.log("fare==>", data);
        setFareDetails(data);
      } catch (err) {
        console.log("error==>", err);
      }
    };

    if (pickup && dropoff && distance) calculateFare();
  }, [distance, vehicleCategory]);

  useEffect(() => {
    setDropoff(null);
  }, [pickup]);

  // if (currentRide) {
  //   // notifyFailure("You already have an active ride!");
  //   return <Navigate to="/current-ride" />;
  // }

  return (
    <>
      <h4 className="page-title">
        {/* <!--Ride Now--> */}
        Book a ride
      </h4>

      <div className="row  pricing_left">
        <div className="col-md-6">
          <form
            // onkeypress="return disableEnterKey(event);"
            className="tripsform"
            onSubmit={submitHandler}
          >
            {location && location.city && location.country && (
              <h5 className="my-4">
                You are based in {location.city.long_name},{" "}
                {location.country.long_name}
              </h5>
            )}
            <div className="row my-3">
              <div className="col-3">
                <span className="ti-control-record">Pickup</span>
              </div>
              <div className="col-9">
                <PlacesSearch
                  placeholder="Enter Pickup"
                  handleAddress={setPickup}
                  setDirections={setDirections}
                  clearDropOffOnChange={() => setDropoff(null)}
                  inputRef={pickupRef}
                  clearInputRef={dropoffRef}
                />
              </div>
            </div>
            <div className="row my-3">
              <div className="col-3">
                <span className="ti-control-record">Dropoff</span>
              </div>
              <div className="col-9">
                <PlacesSearch
                  placeholder="Enter Dropoff"
                  handleAddress={setDropoff}
                  inputRef={dropoffRef}
                  restrictSearch={false}
                  city={pickup?.address?.city}
                  setDirections={setDirections}
                  disabled={!pickup}
                />
              </div>
            </div>
            <div className="row my-3">
              <div className="col-3">
                <span className="ti-control-record">Vehicle Type</span>
              </div>
              <div className="col-9">
                <select
                  type="text"
                  className="form-control fontsize"
                  id="destination-input"
                  name="d_address"
                  placeholder="Enter Destination"
                  value={vehicleCategory}
                  onChange={(e) => setVehicleCategory(e.target.value)}
                >
                  <option value={""}>Select Vehicle Type</option>
                  {vehicleCategories.list.map((x) => (
                    <option value={x._id}>
                      {x.name} {`( ${x.seatingSpace} Person(s) )`}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="row my-3">
              <div className="col-3">
                <span className="ti-control-record">Payment Method</span>
              </div>
              <div className="col-9">
                <select
                  type="text"
                  className="form-control fontsize"
                  value={paymentMethod}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                >
                  <option value={"Cash"}>Cash</option>
                  <option value={"Wallet"}>Wallet</option>
                </select>
              </div>
            </div>
            <div className="my-5">
              <CustomButton
                type="Submit"
                className="btn button-primary shadow-box fare-btn"
                title="Book a ride"
                isLoading={loading}
              />
              {/* <button
                  type="submit"
                  className="btn btn-success shadow-box fare-btn"
                  style={{ width: "100%" }}
                >
                  Book a Ride
                </button> */}
            </div>
            {/* <!-- <button type="button" className="half-secondary-btn fare-btn shadow-box" data-toggle="modal" data-target="#schedule_modal">Schedule Order</button> --> */}
          </form>
        </div>
        <div className="col-md-6">
          <div className="map-responsive">
            <MapComponent
              {...{
                pickup,
                dropoff,
                duration,
                setDuration,
                distance,
                setDistance,
                fareDetails,
                directions,
                setDirections,
                // setFare,
              }}
            />
            {/* <div
                className="bg-primary"
                style={{ width: "100%", height: "500px" }}
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2828.9762715917354!2d-93.31141708447836!3d44.84241658273651!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87f6295d28213dbb%3A0x83a0bec1bc4240a0!2sIconicTek!5e0!3m2!1sen!2s!4v1674049707753!5m2!1sen!2s"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  height="100%"
                  width="100%"
                ></iframe>
              </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default NoRideView;
