import React from "react";
import { NavLink } from "react-router-dom";
import RidesTable from "./RidesTable";

function RidesPage() {
  return (
    <div className="py-2 border-top-0 emp-dashboard">
      <div className="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
        <div id="table-actions" className="flex-grow-1 align-items-center">
          <h4 className="page-title">Rides History</h4>{" "}
        </div>
      </div>
      <RidesTable />
    </div>
  );
}

export default RidesPage;
