import React, { useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <Container className="mt-4">
      <h3>Privacy Policy</h3>

      <p>
        Welcome to Cabtify, the platform connecting riders with drivers. Please
        read these terms and conditions carefully before using our services. By
        accessing or using our platform, you agree to be bound by these terms
        and conditions.
      </p>

      <Row className="mb-3">
        <Col>
          <h5>1. Acceptance of Terms:</h5>
          <p>
            By accessing or using our platform, you agree to these terms and
            conditions, as well as our Privacy Policy. If you do not agree with
            any of these terms, you may not use our services.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h5>2. User Accounts:</h5>
          <p>
            In order to use our platform, you may be required to create a user
            account. You agree to provide accurate and complete information when
            creating your account, and to keep your login credentials secure.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h5>3. Ride Requests and Payments:</h5>
          <p>
            Users can request rides through our platform by specifying their
            pickup and drop-off locations. The fare for each ride will be
            calculated based on various factors, including distance and demand.
            Payment for rides will be processed electronically through the
            platform.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h5>4. Driver and Vehicle Standards:</h5>
          <p>
            We strive to maintain high standards for drivers and vehicles on our
            platform. Drivers must meet certain criteria, including possessing a
            valid driver&#39;s license and maintaining adequate insurance
            coverage. Vehicles must meet safety and cleanliness standards set by
            Cabtify.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h5>5. User Conduct:</h5>
          <p>
            Users are expected to behave respectfully towards drivers and other
            users of the platform. Any form of harassment, discrimination, or
            abusive behavior will not be tolerated and may result in suspension
            or termination of your account.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h5>6. Feedback and Ratings:</h5>
          <p>
            After each ride, users will have the opportunity to provide feedback
            and rate their driver. Similarly, drivers can also rate their
            passengers. This feedback system helps maintain the quality of our
            platform and ensures a positive experience for all users.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h5>7. Cancellation Policy:</h5>
          <p>
            Users may cancel ride requests at any time before the driver arrives
            at the pickup location. However, repeated cancellations may result
            in penalties, including additional fees or account suspension.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h5>8. Liability:</h5>
          <p>
            Cabtify is not liable for any damages, injuries, or losses incurred
            during the use of our platform, including during rides arranged
            through the platform. Users are encouraged to review and understand
            their insurance coverage before using our services.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h5>9. Intellectual Property:</h5>
          <p>
            All content and materials available on our platform, including
            logos, trademarks, and software, are the property of Cabtify and are
            protected by intellectual property laws. Users may not use or
            reproduce these materials without prior authorization.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h5>10. Changes to Terms:</h5>
          <p>
            Cabtify reserves the right to update or modify these terms and
            conditions at any time without prior notice. Any changes will be
            effective immediately upon posting to the platform. Users are
            responsible for regularly reviewing these terms for updates.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h5>9. Termination:</h5>
          <p>
            Cabtify reserves the right to suspend or terminate your account at
            any time for violations of these terms and conditions or for any
            other reason deemed appropriate by Cabtify.
          </p>
        </Col>
      </Row>

      <p>
        By using our platform, you agree to abide by these terms and conditions.
        If you have any questions or concerns about these terms, please contact
        us at <a href="mailto:support@cabtify.com">support@cabtify.com</a>.
        Thank you for choosing Cabtify!
      </p>
    </Container>
  );
};

export default PrivacyPolicy;
