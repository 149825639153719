import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../../components/table";
import { asyncGetRides } from "../../../redux/slices/rideSlice";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import CurrencySymbol from "../../../components/CurrencySymbol";
import { formatRideTime } from "../../../utils/formatTime";

// import AdminClientEditComponentPage from "../../../pages/adminPages/AdminClientEditComponentPage";
// import RightModal from "../../RightModal";
// import AdminClientEditComponent from "./AdminClientEditComponent";

const columns = [
  // {
  //   displayName: "Passenger Name",
  //   displayField: (e) => (
  //     <>
  //       {e.passenger?.firstName} {e.passenger?.lastName}
  //     </>
  //   ),
  //   searchAble: true,
  // },
  {
    displayName: "Driver Name",
    displayField: (e) => (
      <>
        {e.driver?.firstName} {e.driver?.lastName}
      </>
    ),
    searchAble: true,
  },
  {
    displayName: "Pickup",
    displayField: (e) => (
      <>
        {e?.route?.pickup
          ? e?.route?.pickup?.address?.formattedAddress
          : `${e?.rideRequest?.pickup?.address?.formattedAddress} ( Est )`}
      </>
    ),
    searchAble: true,
  },
  {
    displayName: "Dropoff",
    displayField: (e) => (
      <>
        {e?.route?.dropoff
          ? e?.route?.dropoff?.address?.formattedAddress
          : `${e?.rideRequest?.dropoff?.address?.formattedAddress} ( Est )`}
      </>
    ),
    searchAble: true,
  },
  {
    // key: "verified",
    displayName: "Booking Time",
    displayField: (e) => {
      return <>{moment(e?.bookingTime).format("hh:mm a @ DD MMM, YYYY ")}</>;
    },
    searchAble: true,
  },
  {
    displayName: "Distance / Duration",
    displayField: (e) => (
      <>
        {e?.route?.rideEnded
          ? `${e?.route?.distanceFromPickupToDropoff} km / ${e?.route?.pickupToDropoffTimeInMinutes} mins`
          : `${e?.rideRequest?.distance} / ${e?.rideRequest?.duration} ( Est )`}
      </>
    ),
  },
  {
    displayName: "Fare",
    displayField: (e) => (
      <>
        {e?.route?.fareDetails
          ? e?.route?.fareDetails?.totalFare
          : `${e?.rideRequest?.fareDetails?.currency} ${e?.rideRequest?.fareDetails?.totalFare} ( Est )`}
      </>
    ),
  },

  {
    displayName: "Waiting Time",
    displayField: (e) => (
      <>
        {e?.route?.rideStarted ? (
          <>
            {formatRideTime(
              moment(e.rideActions[3].timestamp).diff(
                moment(e.rideActions[2].timestamp),
                "seconds"
              )
            )}
            {/* {e.route.waitingTime} */}
          </>
        ) : (
          <>N/A</>
        )}
      </>
    ),
  },

  {
    displayName: "Ride Time",
    displayField: (e) => (
      <>
        {e?.route?.rideEnded ? (
          <>
            {formatRideTime(
              moment(e.rideActions[4].timestamp).diff(
                moment(e.rideActions[3].timestamp),
                "seconds"
              )
            )}
          </>
        ) : (
          <>N/A</>
        )}
      </>
    ),
  },
  {
    key: "status",
    displayName: "Status",
  },
  {
    displayName: "Action",
    key: "",
    displayField: (e) => (
      <div className="d-flex justify-content-start align-items-center">
        {/* <Link className="mr-2" to="" title="Update Rider">
            <i className="fas fa-edit text-primary" />
          </Link> */}
        <Link className="mr-2" to={`/rides/${e._id}`}>
          <i className="fas fa-eye text-secondary" />
        </Link>
        {/* <a
            className="mr-2 text-danger"
            href="javascript:void(0)"
            data--submit="rider36"
            data--confirmation="true"
            data-title="Delete Rider"
            title="Delete Rider"
            data-message="Are you sure you want to delete?"
          >
            <i className="fas fa-trash-alt" />
          </a> */}
      </div>
    ),
    searchAble: true,
  },
];

const RidesTable = () => {
  const dispatch = useDispatch();
  // const auth = useSelector((state) => state.auth);
  const rides = useSelector((state) => state.rides);

  const { isLoading, list } = rides;
  // const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  // const openMoreVertDropDown = () => {
  //   setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  // };

  // const [modal, setModal] = useState({ open: false, data: null });

  // const handleModal = (data) => {
  //   setModal({ open: !modal.open, data: data });
  // };
  // const [currentPageNumber, setCurrentPageNumber] = useState(1);
  // const [limit, setLimit] = useState(10);
  // const handleLimit = async (event) => {
  //   setLimit(parseInt(event.target.value));
  //   dispatch(
  //     getAllUsers(
  //       { companyId: auth?.user?.companyId?._id, isClient: true },
  //       event.target.value,
  //       1
  //     )
  //   );
  //   setCurrentPageNumber(1);
  // };

  // const onClickNext = async () => {
  //   let i = currentPageNumber;
  //   i++;
  //   if (i <= users.client.totalPages) {
  //     setCurrentPageNumber(currentPageNumber + 1);
  //     dispatch(
  //       getAllUsers(
  //         { companyId: auth?.user?.companyId?._id, isClient: true },
  //         limit,
  //         currentPageNumber + 1
  //       )
  //     );
  //   }
  // };

  // const onClickPrev = async () => {
  //   let i = currentPageNumber;
  //   i--;
  //   if (i >= 1) {
  //     setCurrentPageNumber(currentPageNumber - 1);
  //     dispatch(
  //       getAllUsers(
  //         { companyId: auth?.user?.companyId?._id, isClient: true },
  //         limit,
  //         currentPageNumber - 1
  //       )
  //     );
  //   }
  // };

  // const handleChangeData = async (pageNo) => {
  //   setCurrentPageNumber(pageNo);
  //   dispatch(
  //     getAllUsers(
  //       { companyId: auth?.user?.companyId?._id, isClient: true },
  //       limit,
  //       pageNo
  //     )
  //   );
  // };

  useEffect(() => {
    dispatch(asyncGetRides());
  }, []);

  return (
    <>
      <Table dataSource={list} isLoading={isLoading} columns={columns} />
      {/* <RightModal open={modal.open} handleModal={handleModal}>
        {modal.open && (
          <AdminClientEditComponent
            state={modal.data}
            handleModal={handleModal}
          />
        )}
      </RightModal> */}
    </>
  );
};
export default RidesTable;
