import React from "react";

function TableHead({ itemsPerPage, setItemsPerPage, setCurrentPage }) {
  return (
    <div className="d-flex border">
      <div className="flex-grow-1">
        <div className="dataTables_length d-flex" id="leads-table_length">
          <div className="p-2">
            <label>Show entries</label>
          </div>
          <label>
            <select
              name="leads-table_length"
              aria-controls="leads-table"
              className="custom-select custom-select-sm form-control form-control-sm"
              // className="select"
              autoComplete="false"
              onChange={(e) => {setItemsPerPage(e.target.value); setCurrentPage(1)}}
            >
              {[10, 25, 50, 100].map((current, i) => {
                return (
                  <option value={current} key={i}>
                    {current}
                  </option>
                );
              })}
            </select>
          </label>
        </div>
      </div>
      {/* <!-- SEARCH BY TASK START --> */}
      {/* <div className="task-search d-flex  py-1 px-lg-3 px-0 border-right-grey align-items-center">
                <form className="w-100 mr-1 mr-lg-0 mr-md-1 ml-md-1 ml-0 ml-lg-0">
                    <div className="input-group bg-grey rounded">
                        <div className="input-group-prepend">
                            <span className="input-group-text border-0 bg-additional-grey">
                                <i className="fa fa-search f-13 text-dark-grey"></i>
                            </span>
                        </div>
                        <input type="text" className="form-control f-14 p-1 border-additional-grey" id="search-text-field" placeholder="Start typing to search" />
                    </div>
                </form>
            </div> */}
    </div>
  );
}

export default TableHead;
