import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../../components/table";
import { asyncGetWalletTransactions } from "../../../redux/slices/walletSlice";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import CurrencySymbol from "../../../components/CurrencySymbol";
import { formatRideTime } from "../../../utils/formatTime";

// import AdminClientEditComponentPage from "../../../pages/adminPages/AdminClientEditComponentPage";
// import RightModal from "../../RightModal";
// import AdminClientEditComponent from "./AdminClientEditComponent";

const columns = [
  // {
  //   displayName: "Passenger Name",
  //   displayField: (e) => (
  //     <>
  //       {e.passenger?.firstName} {e.passenger?.lastName}
  //     </>
  //   ),
  //   searchAble: true,
  // },
  {
    displayName: "Amount",
    displayField: (e) => (
      <>
        {e.currency} {e.amount}
      </>
    ),
    searchAble: true,
  },
  {
    displayName: "Type",
    displayField: (e) => <>{e?.type}</>,
    searchAble: true,
  },
  {
    displayName: "Payment Method",
    displayField: (e) => <>{e?.paymentMethod}</>,
    searchAble: true,
  },
  {
    displayName: "Description",
    displayField: (e) => <>{e?.description}</>,
    searchAble: true,
  },
  // {
  //   displayName: "Wallet Balance",
  //   displayField: (e) => (
  //     <>
  //       {e?.balance}
  //     </>
  //   ),
  //   searchAble: true,
  // },
  {
    displayName: "Time",
    displayField: (e) => (
      <>{moment(e?.updatedAt).format("hh:mm a @ DD MMM, YYYY")}</>
    ),
  },
  {
    key: "status",
    displayName: "Status",
  },
];
const TransactionsTable = () => {
  const auth = useSelector((state) => state.auth);
  const walletTransactions = useSelector((state) => state.wallet.transactions);

  const { isLoading, list } = walletTransactions;

  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };

  const [modal, setModal] = useState({ open: false, data: null });

  const handleModal = (data) => {
    setModal({ open: !modal.open, data: data });
  };
  const dispatch = useDispatch();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  // const handleLimit = async (event) => {
  //   setLimit(parseInt(event.target.value));
  //   dispatch(
  //     getAllUsers(
  //       { companyId: auth?.user?.companyId?._id, isClient: true },
  //       event.target.value,
  //       1
  //     )
  //   );
  //   setCurrentPageNumber(1);
  // };

  // const onClickNext = async () => {
  //   let i = currentPageNumber;
  //   i++;
  //   if (i <= users.client.totalPages) {
  //     setCurrentPageNumber(currentPageNumber + 1);
  //     dispatch(
  //       getAllUsers(
  //         { companyId: auth?.user?.companyId?._id, isClient: true },
  //         limit,
  //         currentPageNumber + 1
  //       )
  //     );
  //   }
  // };

  // const onClickPrev = async () => {
  //   let i = currentPageNumber;
  //   i--;
  //   if (i >= 1) {
  //     setCurrentPageNumber(currentPageNumber - 1);
  //     dispatch(
  //       getAllUsers(
  //         { companyId: auth?.user?.companyId?._id, isClient: true },
  //         limit,
  //         currentPageNumber - 1
  //       )
  //     );
  //   }
  // };

  // const handleChangeData = async (pageNo) => {
  //   setCurrentPageNumber(pageNo);
  //   dispatch(
  //     getAllUsers(
  //       { companyId: auth?.user?.companyId?._id, isClient: true },
  //       limit,
  //       pageNo
  //     )
  //   );
  // };

  useEffect(() => {
    dispatch(asyncGetWalletTransactions());
  }, []);

  return (
    <>
      <Table dataSource={list} isLoading={isLoading} columns={columns} />
    </>
  );
};
export default TransactionsTable;
