import React from "react";
import { Link } from "react-router-dom";
import TransactionsTable from "./TransactionsTable";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetWallet } from "../../../redux/slices/walletSlice";
import { useEffect } from "react";
import AddFundsModal from "./AddFundsModal";
import { useState } from "react";
import MobileMoneyDetailsModal from "./MobileMoneyDetailsModal";
import CustomButton from "../../../components/CustomButton";

function Wallet() {
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet);
  const { data, transactions } = wallet;
  console.log(data, "dataFormat");

  const [showPopup, setShowPopup] = useState(false);
  const [showMobileMoneyPopup, setShowMobileMoneyPopup] = useState(false);

  const closePopup = () => {
    setShowPopup(false);
  };
  const closeMobileMoneyPopup = () => {
    setShowMobileMoneyPopup(false);
  };

  return (
    <>
      <div className="py-2 border-top-0 emp-dashboard">
        <div className="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
          <div id="table-actions" className="flex-grow-1 align-items-center">
            <h4 className="page-title">Your Wallet</h4>{" "}
          </div>
        </div>

        <div className="row">
          {data ? (
            <div className="col-12 text-center">
              <div className="card card-block">
                <div className="card-header mb-4">
                  <h5>Your Balance</h5>
                </div>
                <div className="card-body">
                  <h3>
                    {data.currency} {data.amount}
                  </h3>
                </div>
                <div className=" mb-4">
                  <CustomButton
                    type="submit"
                    title="Add Funds"
                    className="btn button-primary"
                    onClick={() => setShowPopup(true)}
                  />{" "}
                  <CustomButton
                    type="submit"
                    title="Mobile Money Details"
                    className="btn button-primary"
                    onClick={() => setShowMobileMoneyPopup(true)}
                  />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {transactions ? (
            <div className="py-2 border-top-0 emp-dashboard">
              <div className="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
                <div
                  id="table-actions"
                  className="flex-grow-1 align-items-center"
                >
                  <h4 className="page-title">Transactions</h4>{" "}
                </div>
              </div>
              <TransactionsTable />
            </div>
          ) : (
            <></>
          )}
        </div>
        {/* <div className="row mt-4">
        <div className="col-md-6">
          <div className="card">
            <div className="card-header">Add Funds</div>
            <div className="card-body">
              <form>
                <div className="form-group my-4">
                  <label for="addFundsAmount">Amount:</label>
                  <input type="text" className="form-control" id="addFundsAmount" />
                </div>
                <button type="submit" className="btn btn-primary">
                  Add Funds
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="card">
            <div className="card-header">Send Money</div>
            <div className="card-body">
              <form>
                <div className="form-group">
                  <label for="recipient">Recipient:</label>
                  <input type="text" className="form-control" id="recipient" />
                </div>
                <div className="form-group">
                  <label for="sendAmount">Amount:</label>
                  <input type="text" className="form-control" id="sendAmount" />
                </div>
                <button type="submit" className="btn btn-primary">
                  Send Money
                </button>
              </form>
            </div>
          </div>
        </div>
      </div> */}
      </div>

      {showPopup && <AddFundsModal show={showPopup} closePopup={closePopup} />}
      <MobileMoneyDetailsModal
        show={showMobileMoneyPopup}
        closePopup={closeMobileMoneyPopup}
      />
    </>
  );
}

export default Wallet;
