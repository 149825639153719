import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosPrivate, { axiosPublic } from "../../services/api";
import toast from "react-hot-toast";

export const asyncSendOtp = createAsyncThunk(
  "sendOtp",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const { data, callback } = payload;

    try {
      const response = await axiosPublic.post("/passengers/send-otp", data);
      toast.success("OTP sent successfully!");
      callback && callback();
      return fulfillWithValue({ ...response.data, ...data });
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncResendOtp = createAsyncThunk(
  "resendOtp",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const { data, callback } = payload;
    try {
      const response = await axiosPublic.post("/passengers/send-otp", {
        phone: getState().auth.phone,
      });
      toast.success("OTP resent successfully!");
      callback && callback();

      return fulfillWithValue({ ...response.data });
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncVerifyOtp = createAsyncThunk(
  "verifyOtp",
  async (
    payload,
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const { data, callback } = payload;

    try {
      const response = await axiosPublic.post("/passengers/verify-otp", data);
      toast.success("OTP verified successfully!");
      callback && callback();
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      callback && callback();

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncCompleteRegisteration = createAsyncThunk(
  "completeRegisteration",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.patch(
        "/passengers/completeRegisteration",
        obj
      );
      toast.success("Registeration completed successfully!");

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

// export const asyncGetPassengerByToken = createAsyncThunk(
//   "completeRegisteration",
//   async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
//     try {
//       const response = await axiosPrivate.patch(
//         "/passengers/completeRegisteration",
//         obj
//       );
//       toast.success("Registeration completed successfully!");

//       return fulfillWithValue(response.data);
//     } catch (error) {
//       console.log("err==>", error.response.data.message);
//       toast.error(error.response.data.message);

//       throw rejectWithValue(error.response.data.message);
//     }
//   }
// );

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoading: false,
    tokens: localStorage.getItem("cabtifyPassengerTokens")
      ? JSON.parse(localStorage.getItem("cabtifyPassengerTokens"))
      : null,
    newRegister: false,
    userInfo: localStorage.getItem("cabtifyPassengerInfo")
      ? JSON.parse(localStorage.getItem("cabtifyPassengerInfo"))
      : null,
    isError: false,
  },
  extraReducers: (builder) => {
    // Send OTP cases
    builder.addCase(asyncSendOtp.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncSendOtp.fulfilled, (state, action) => {
      // localStorage.setItem(
      //   "cabtifyTokens",
      //   JSON.stringify(action.payload.tokens)
      // );
      state.isLoading = false;
      state.otpSent = true;
      state.phone = action.payload.phone;
      // state.userInfo = action.payload.user;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncSendOtp.rejected, (state, action) => {
      // console.log("Error", action);
      state.isLoading = false;
      state.otpSent = false;
      state.message = action.payload;
      state.isError = true;
    });

    // Resend OTP cases
    builder.addCase(asyncResendOtp.pending, (state, action) => {
      state.isLoading = true;
      state.otpResent = false;
    });
    builder.addCase(asyncResendOtp.fulfilled, (state, action) => {
      // localStorage.setItem(
      //   "cabtifyTokens",
      //   JSON.stringify(action.payload.tokens)
      // );
      state.isLoading = false;
      state.otpResent = true;
      // state.phone = action.payload.phone;
      // state.userInfo = action.payload.user;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncResendOtp.rejected, (state, action) => {
      // console.log("Error", action);
      state.isLoading = false;
      state.otpSent = false;
      state.message = action.payload;
      state.isError = true;
    });

    // Verify OTP Cases
    builder.addCase(asyncVerifyOtp.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncVerifyOtp.fulfilled, (state, action) => {
      if (action.payload.tokens)
        localStorage.setItem(
          "cabtifyPassengerTokens",
          JSON.stringify(action.payload.tokens)
        );
      if (action.payload.passenger)
        localStorage.setItem(
          "cabtifyPassengerInfo",
          JSON.stringify(action.payload.passenger)
        );
      state.isLoading = false;
      state.otpSent = true;
      state.otpVerified = true;
      state.phone = action.payload.phone;
      state.userInfo = action.payload.passenger;
      state.tokens = action.payload.tokens;
      state.newRegister = action.payload.newRegister;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncVerifyOtp.rejected, (state, action) => {
      // console.log("Error", action);
      state.isLoading = false;
      state.otpSent = true;
      state.otpVerified = false;
      state.message = action.payload;
      state.isError = true;
    });

    // Complete Registeration Cases
    builder.addCase(asyncCompleteRegisteration.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncCompleteRegisteration.fulfilled, (state, action) => {
      if (action.payload.tokens)
        localStorage.setItem(
          "cabtifyPassengerTokens",
          JSON.stringify(action.payload.tokens)
        );
      if (action.payload.tokens)
        localStorage.setItem(
          "cabtifyPassengerInfo",
          JSON.stringify(action.payload.passenger)
        );
      state.isLoading = false;
      state.otpSent = true;
      state.otpVerified = true;
      state.phone = action.payload.phone;
      state.userInfo = action.payload.passenger;
      state.tokens = action.payload.tokens;
      state.registerationCompleted = true;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncCompleteRegisteration.rejected, (state, action) => {
      // console.log("Error", action);
      state.isLoading = false;
      state.otpSent = true;
      state.otpVerified = false;
      state.message = action.payload;
      state.isError = true;
    });
  },
  reducers: {
    changeNumber(state, action) {
      state.otpSent = false;
      state.otpResent = false;
      state.phone = "";
    },
    logout(state, action) {
      localStorage.removeItem("cabtifyPassengerTokens");
      localStorage.removeItem("cabtifyPassengerInfo");

      state.otpSent = false;
      state.otpResent = false;
      state.phone = "";
      state.userInfo = null;
      state.tokens = null;
    },
  },
});

export const { changeNumber, logout } = authSlice.actions;
export default authSlice.reducer;
