import React, { useEffect } from "react";
import {
  GoogleMap,
  Polyline,
  LoadScript,
  useLoadScript,
  PolylineF,
  MarkerF,
  InfoWindowF,
} from "@react-google-maps/api";
import { GOOGLE_API_KEY } from "../../constants";
import { useState } from "react";
import axios from "axios";

const MapWithRoute = ({
  rideWaypoints,
  waypointsToPickup,
  pickupLocation,
  dropoffLocation,
  startLocation,
}) => {
  const mapStyles = {
    height: "400px",
    width: "100%",
  };

  const [activeMarker, setActiveMarker] = useState(null);
  const [smoothPolyline, setSmoothPolyline] = useState([]);
  const [smoothPolylines, setSmoothPolylines] = useState([]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    // libraries: libraries,
  });

  const handleMarkerClick = (marker) => {
    setActiveMarker(marker);
  };

  // useEffect(() => {
  //   // Fetch directions from Google Maps Directions API
  //   if (rideWaypoints.length > 1) {
  //     const waypoints = rideWaypoints
  //       .map(
  //         (waypoint) => `${waypoint.coordinates[0]},${waypoint.coordinates[1]}`
  //       )
  //       .join("|");
  //     fetch(
  //       `https://maps.googleapis.com/maps/api/directions/json?origin=${
  //         rideWaypoints[0].coordinates[0]
  //       },${rideWaypoints[0].coordinates[1]}&destination=${
  //         rideWaypoints[rideWaypoints.length - 1].coordinates[0]
  //       },${
  //         rideWaypoints[rideWaypoints.length - 1].coordinates[1]
  //       }&waypoints=${waypoints}&key=${GOOGLE_API_KEY}`
  //     )
  //       .then(async(response) => {
  //         const data = await response.json();
  //         console.log("data==>", data);
  //         if (data.routes && data.routes.length > 0) {
  //           const overviewPolyline = data.routes[0].overview_polyline.points;
  //           const decodedPoints = decodePolyline(overviewPolyline);
  //           setSmoothPolyline(decodedPoints);
  //         }
  //       })

  //       .catch((error) => console.error("Error fetching directions:", error));
  //   }
  // }, [rideWaypoints]);

  // useEffect(() => {
  //   // Fetch directions from Google Maps Directions API
  //   if (rideWaypoints.length > 1) {
  //     const chunkSize = 10; // Adjust the chunk size as needed
  //     const chunkedWaypoints = [];
  //     for (let i = 0; i < rideWaypoints.length; i += chunkSize) {
  //       chunkedWaypoints.push(rideWaypoints.slice(i, i + chunkSize));
  //     }

  //     const requests = chunkedWaypoints.map((chunk) => {
  //       const waypoints = chunk
  //         .map(
  //           (waypoint) =>
  //             `${waypoint.coordinates[0]},${waypoint.coordinates[1]}`
  //         )
  //         .join("|");
  //       return fetch(
  //         `https://maps.googleapis.com/maps/api/directions/json?origin=${
  //           chunk[0].coordinates[0]
  //         },${chunk[0].coordinates[1]}&destination=${
  //           chunk[chunk.length - 1].coordinates[0]
  //         },${
  //           chunk[chunk.length - 1].coordinates[1]
  //         }&waypoints=${waypoints}&key=${GOOGLE_API_KEY}`
  //       ).then((response) => response.json());
  //     });

  //     Promise.all(requests)
  //       .then((responses) => {
  //         const decodedPolylines = responses.map((response) => {
  //           if (response.routes && response.routes.length > 0) {
  //             return decodePolyline(
  //               response.routes[0].overview_polyline.points
  //             );
  //           }
  //           return [];
  //         });
  //         setSmoothPolylines(decodedPolylines.flat());
  //       })
  //       .catch((error) => console.error("Error fetching directions:", error));
  //   }
  // }, [rideWaypoints]);

  // // Function to decode a polyline encoded string
  // const decodePolyline = (encoded) => {
  //   const points = [];
  //   let index = 0,
  //     len = encoded.length;
  //   let lat = 0,
  //     lng = 0;

  //   while (index < len) {
  //     let b,
  //       shift = 0,
  //       result = 0;
  //     do {
  //       b = encoded.charCodeAt(index++) - 63;
  //       result |= (b & 0x1f) << shift;
  //       shift += 5;
  //     } while (b >= 0x20);

  //     const dlat = result & 1 ? ~(result >> 1) : result >> 1;
  //     lat += dlat;

  //     shift = 0;
  //     result = 0;
  //     do {
  //       b = encoded.charCodeAt(index++) - 63;
  //       result |= (b & 0x1f) << shift;
  //       shift += 5;
  //     } while (b >= 0x20);

  //     const dlng = result & 1 ? ~(result >> 1) : result >> 1;
  //     lng += dlng;

  //     points.push({ lat: lat / 1e5, lng: lng / 1e5 });
  //   }

  //   return points;
  // };

  // console.log("smooth==>", smoothPolyline);

  useEffect(() => {
    // Fetch smoothed polyline from Google Maps Roads API (Snap to Roads)
    if (rideWaypoints.length > 1) {
      const waypoints = rideWaypoints.map(
        (waypoint) => `${waypoint.coordinates[0]},${waypoint.coordinates[1]}`
      );
      fetchSnappedPoints(waypoints);
    }
  }, [rideWaypoints]);

  const fetchSnappedPoints = async (waypoints) => {
    try {
      const response = await axios.get(
        `https://roads.googleapis.com/v1/snapToRoads?path=${waypoints.join(
          "|"
        )}&interpolate=true&key=${GOOGLE_API_KEY}`
      );

      console.log("response==>", response);
      if (response.data.snappedPoints) {
        const snappedPolyline = response.data.snappedPoints.map((point) => ({
          lat: point.location.latitude,
          lng: point.location.longitude,
        }));
        setSmoothPolyline(snappedPolyline);
      }
    } catch (error) {
      console.error("Error fetching snapped points:", error);
    }
  };

  console.log("smooth polyline==>", smoothPolyline);
  if (!isLoaded) {
    return null; // Don't render the component until libraries are loaded
  }
  return (
    <GoogleMap
      mapContainerStyle={mapStyles}
      zoom={15}
      center={{
        lat: pickupLocation[0],
        lng: pickupLocation[1],
      }} // Center the map on the first point of your route
    >
      <PolylineF
        path={waypointsToPickup.map((x) => ({
          lat: x.coordinates[0],
          lng: x.coordinates[1],
        }))}
        geodesic={true}
        options={{
          strokeColor: "#000",
          strokeOpacity: 0.75,
          strokeWeight: 5,
        }}
      />

      {/* {smoothPolyline.length > 0 && (
        <Polyline
          path={smoothPolyline}
          geodesic={true}
          options={{
            strokeColor: "green", // Change the color if needed
            strokeOpacity: 0.75,
            strokeWeight: 5,
          }}
        />
      )} */}

      {smoothPolyline.length > 0 && (
        <Polyline
          path={smoothPolyline}
          geodesic={true}
          options={{
            strokeColor: "green", // Change the color if needed
            strokeOpacity: 0.75,
            strokeWeight: 5,
          }}
        />
      )}

      <PolylineF
        path={rideWaypoints.map((x) => ({
          lat: x.coordinates[0],
          lng: x.coordinates[1],
        }))}
        geodesic={true}
        options={{
          strokeColor: "blue",
          strokeOpacity: 0.75,
          strokeWeight: 5,
          // icons: [
          //   {
          //     // icon: lineSymbol,
          //     offset: "0",
          //     repeat: "20px",
          //   },
          // ],
        }}
      />

      {startLocation && (
        <MarkerF
          position={{
            lat: startLocation[0],
            lng: startLocation[1],
          }}
          onMouseOver={() => handleMarkerClick(startLocation)}
          onMouseOut={() => handleMarkerClick(null)}
        >
          {activeMarker === startLocation && (
            <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
              {/* <div> */}
              <p className="fw-semibold">This is Driver start location!</p>
              {/* </div> */}
            </InfoWindowF>
          )}
        </MarkerF>
      )}

      {pickupLocation && (
        <MarkerF
          position={{
            lat: pickupLocation[0],
            lng: pickupLocation[1],
          }}
          onMouseOver={() => handleMarkerClick(pickupLocation)}
          onMouseOut={() => handleMarkerClick(null)}
        >
          {activeMarker === pickupLocation && (
            <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
              <div>
                <p className="fw-semibold">This is pickup location!</p>
              </div>
            </InfoWindowF>
          )}
        </MarkerF>
      )}

      {dropoffLocation && (
        <MarkerF
          position={{
            lat: dropoffLocation[0],
            lng: dropoffLocation[1],
          }}
          onMouseOver={() => handleMarkerClick(dropoffLocation)}
          onMouseOut={() => handleMarkerClick(null)}
        >
          {activeMarker === dropoffLocation && (
            <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
              {/* <div> */}
              <p className="fw-semibold">This is dropoff location!</p>
              {/* </div> */}
            </InfoWindowF>
          )}
        </MarkerF>
      )}
    </GoogleMap>
  );
};

export default MapWithRoute;
