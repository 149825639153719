import React from "react";
import { useSelector } from "react-redux";
import NoRideView from "./NoRideView";
import RideView from "./RideView";

function RideNow() {
  const rides = useSelector((state) => state.rides);
  const { currentRide } = rides;
  return <div>{!currentRide ? <NoRideView /> : <RideView />}</div>;
}

export default RideNow;
