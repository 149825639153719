import React, { useRef, useState } from "react";
import {
  StandaloneSearchBox,
  LoadScript,
  useLoadScript,
  Autocomplete,
} from "@react-google-maps/api";
import { GOOGLE_API_KEY } from "../../constants";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const libraries = ["places"];
const PlacesSearch = ({
  handleAddress,
  placeholder,
  restrictSearch,
  city,
  setDirections,
  clearDropOffOnChange,
  inputRef,
  clearInputRef,
  disabled,
}) => {
  const location = useSelector((state) => state.location);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: libraries,
  });

  const [results, setResults] = useState([]);
  const [placeSelected, setPlaceSelected] = useState(true);

  // const handlePlaceChanged = () => {
  //   console.log("reff in chanege==>", inputRef);
  //   const place = inputRef.current.getPlace();
  //   if (place) {
  //     const formattedPlace = {
  //       location: {
  //         type: "Point",
  //         coordinates: [
  //           place.geometry.location.lat(),
  //           place.geometry.location.lng(),
  //         ],
  //       },
  //       address: {
  //         name: place.name,
  //         street: place.address_components.find((x) =>
  //           x.types.includes("sublocality_level_2")
  //         )?.long_name,
  //         area: place.address_components.find((x) =>
  //           x.types.includes("sublocality_level_1")
  //         )?.long_name,
  //         city: place.address_components.find((x) =>
  //           x.types.includes("locality")
  //         )?.long_name,
  //         state: place.address_components.find((x) =>
  //           x.types.includes("administrative_area_level_1")
  //         )?.long_name,
  //         // postalCode: place.address_components.find((x) =>
  //         //   x.types.includes("postal_code")
  //         // )?.long_name,
  //         country: place.address_components.find((x) =>
  //           x.types.includes("country")
  //         )?.long_name,
  //         formattedAddress: place.formatted_address,
  //       },
  //     };
  //     if (handleAddress) handleAddress(formattedPlace);
  //     // console.log(place);
  //     // console.log(formattedPlace);
  //   }
  // };

  const selectPlaceHandler = (placeId) => {
    if (placeId) {
      setPlaceSelected(true);
      if (clearDropOffOnChange) {
        clearDropOffOnChange();
        clearInputRef.current.value = "";
        setDirections(null);
        // setPlaceSelected(false)
      }
      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      service.getDetails({ placeId }, (place, status) => {
        if (status === "OK") {
          console.log(place);
          // setPlaceDetails(result);

          if (place) {
            inputRef.current.value = place.formatted_address;
            const formattedPlace = {
              location: {
                type: "Point",
                coordinates: [
                  place.geometry.location.lat(),
                  place.geometry.location.lng(),
                ],
              },
              address: {
                name: place.name,
                street: place.address_components.find((x) =>
                  x.types.includes("sublocality_level_2")
                )?.long_name,
                area: place.address_components.find((x) =>
                  x.types.includes("sublocality_level_1")
                )?.long_name,
                city: place.address_components.find((x) =>
                  x.types.includes("locality")
                )?.long_name,
                state: place.address_components.find((x) =>
                  x.types.includes("administrative_area_level_1")
                )?.long_name,
                // postalCode: place.address_components.find((x) =>
                //   x.types.includes("postal_code")
                // )?.long_name,
                country: place.address_components.find((x) =>
                  x.types.includes("country")
                )?.long_name,
                formattedAddress: place.formatted_address,
              },
            };
            console.log("formatted place==>", formattedPlace);
            if (handleAddress) handleAddress(formattedPlace);
            // console.log(place);
            // console.log(formattedPlace);
          }
        }
      });
    }
  };

  const handlePlaceChanged = () => {
    setPlaceSelected(false);

    const autocompleteService =
      new window.google.maps.places.AutocompleteService();

    autocompleteService.getPlacePredictions(
      {
        input: inputRef.current.value,
        componentRestrictions: { country: location.country.short_name },
        // componentRestrictions: { country: "US" },
      },
      (predictions) => {
        if (predictions && predictions.length > 0) {
          console.log("place predictions==>", predictions, city);
          if (restrictSearch) {
            const cityPredictions = predictions.filter((prediction) =>
              prediction.terms.some((term) =>
                term.value.toLowerCase().includes(city.toLowerCase())
              )
            );

            setResults(cityPredictions);
            console.log("city predicions==>", cityPredictions);
          } else {
            setResults(predictions);
          }

          // Handle karachiPredictions as needed
        }
      }
    );
  };

  useEffect(() => {
    if (inputRef.current) {
      // inputRef.current.value = "";
      // handleAddress(null);
      setDirections(null);
    }
  }, [inputRef.current]);

  if (!isLoaded) {
    return null; // Don't render the component until libraries are loaded
  }

  return (
    <>
      {/* <Autocomplete
        onLoad={(ref) => (inputRef.current = ref)}
        onPlaceChanged={handlePlaceChanged}
        // options={{
        //   // country: "pk", // Restrict to Pakistan

        //   // types: ["regions"], // Restrict to regions, like cities
        //   // types: ["address"], // Restrict to addresses

        //   componentRestrictions: { country: "PK" }, // Restrict to Pakistan
        // }}
        restrictions={{
          country: "PK",
          // city: "Karachi"
        }}
        // options={{
        //   // types: ["address"], // Restrict to addresses
        //   componentRestrictions: { country: "PK" }, // Restrict to Karachi, Pakistan
        // }}
      > */}
      {/* <StandaloneSearchBox
      // <LoadScript googleMapsApiKey={GOOGLE_API_KEY} libraries={["places"]}>
      onLoad={(ref) => (inputRef.current = ref)}
      onPlacesChanged={handlePlaceChanged}
      
      options={{
        componentRestrictions: { country: "pk", locality: "Karachi" },
      }}
    > */}
      <div className="position-relative">
        <input
          type="text"
          className="form-control"
          placeholder={placeholder}
          ref={inputRef}
          onChange={handlePlaceChanged}
          disabled={disabled}
        />
        {!placeSelected && results.length > 0 && (
          <ul className="autocomplete-results dropdown-menu show w-100">
            {results.map((x, index) => (
              <li
                key={index}
                className="dropdown-item text-truncate cursor-pointer"
                onClick={() => selectPlaceHandler(x.place_id)}
              >
                {x.description}
              </li>
            ))}
          </ul>
        )}
      </div>
      {/* </LoadScript> */}
      {/* </StandaloneSearchBox> */}
      {/* </Autocomplete> */}
    </>
  );
};

export default PlacesSearch;
