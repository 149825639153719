import React, { useEffect, useRef, useState } from "react";
import "react-phone-number-input/style.css";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncCompleteRegisteration,
  // asyncResendOtp,
  // asyncSendOtp,
  // asyncVerifyOtp,
  // changeNumber,
} from "../../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";

function PassengerDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);
  // const { otpSent, otpResent, registerationCompleted, phone } = auth;

  const [data, setData] = useState({ isGoogleAuth: false });

  // const isMounted = useRef(true);

  const submitHandler = async (e) => {
    console.log(e);
    e.preventDefault();
    dispatch(asyncCompleteRegisteration(data));
  };

  useEffect(() => {
    if (auth.registerationCompleted) navigate("/ride-now");
    // if (auth.userInfo) {
    //   if (auth.newRegister) navigate("/register");
    //   else navigate("/book-a-ride");
    // }
  }, [auth]);

  // useEffect(() => {
  //   if (!otpSent) navigate("/login");
  // }, [otpSent]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  return (
    <div className=" form-bg">
      {/* <!-- Section: Design Block --> */}
      <section>
        <div className="bookride-section">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-7 mb-lg-0 form-padding">
                <div className="card-bg">
                  <div className="card-body py-5 px-md-5">
                    <form onSubmit={submitHandler} className=" p-3">
                      <div className="text-center p-2 mb-3">
                        <h3>Complete your registration</h3>
                      </div>
                      <div className="col-12 col-md-8 mt-4 mx-auto">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          onChange={handleChange}
                          name="firstName"
                          required
                        />
                      </div>{" "}
                      <div className="col-12 col-md-8 mt-4 mx-auto">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          onChange={handleChange}
                          name="lastName"
                          required
                        />
                      </div>{" "}
                      <div className="col-12 col-md-8 mt-4 mx-auto">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          onChange={handleChange}
                          name="email"
                          required
                        />
                      </div>{" "}
                      <div className="col-12 col-md-8 mt-4 mx-auto">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Password"                    
                          onChange={handleChange}
                          name="password"
                          required
                        />                 
                      </div>           
                      <div className="col-12 col-md-8 mt-4 mx-auto">                        
                        <div className="d-grid">
                          <button
                            className="btn btn-warning mb-4 p-2"
                            type="submit"
                          >
                            Submit
                          </button>                    
                        </div>         
                      </div>{" "}
                      {/* <div className="d-grid">
                        <button className="btn btn-warning mb-4 p-2" type="submit">
                          Continue
                        </button>
                      </div> */}
                      {/* <div className="mt-4">
                        <div className="book-text mt-4">
                          <p className="text-sm">
                            This page is protected by reCAPTCHA and is subject
                            to the Google <Link to="">Privacy Policy</Link> and{" "}
                            <Link to="">Terms of Service</Link>
                          </p>
                        </div>
                      </div> */}
                    </form>             
                  </div>
                </div>
              </div>
            </div>           
          </div>
        </div>
      </section>
    </div>
  );
}

export default PassengerDetails;
