import React, { useEffect, useState } from "react";
// import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { useDispatch, useSelector } from "react-redux";
import { asyncSendOtp } from "../../redux/slices/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { notifyFailure } from "../../components/toast";
import CustomButton from "../../components/CustomButton";
import { asyncGetCountry } from "../../redux/slices/countrySlice";
import LoadingComponent from "../../components/loading/FullScreenLoader";

function PhoneLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const countriesList = useSelector((state) => state.country);
  console.log(countriesList, "countriesList");

  const auth = useSelector((state) => state.auth);
  const location = useSelector((state) => state.location);
  const { country } = location;
  const { otpSent } = auth;

  const [phone, setPhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  console.log("phone==>", phone);

  const submitHandler = async (e) => {
    console.log(e);
    e.preventDefault();
    // Define a regular expression pattern for a valid phone number
    const phonePattern = /^(\+\d{1,3})?\d{6,14}$/; // Customize as needed

    if (!phonePattern.test(phone)) {
      notifyFailure("Please enter valid phone number!");
      return; // Don't submit the form
    }
    setIsLoading(true);
    dispatch(
      asyncSendOtp({
        data: { phone: phone.includes("+") ? phone : "+" + phone },
        callback: () => {
          setIsLoading(false);
        },
      })
    );
  };

  useEffect(() => {
    dispatch(asyncGetCountry());
  }, []);

  useEffect(() => {
    if (auth.otpSent) navigate("/verify-otp");
    if (auth.userInfo) navigate("/ride-now");
  }, [auth]);

  if (countriesList?.list.length === 0) {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center h-100">
          <LoadingComponent />
        </div>
      </>
    );
  }

  return (
    <div className=" form-bg">
      {/* <!-- Section: Design Block --> */}
      <section>
        <div className="bookride-section">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-7 mb-lg-0 form-padding">
                <div className="card-bg">
                  <div className="card-body py-5 px-md-5">
                    <form onSubmit={submitHandler} className="p-3">
                      <div className="text-center p-2 mb-3">
                        <h3>Continue with Cabtify</h3>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-12 mt-4 mx-auto">
                          <div className="form-outline">
                            {/* <input
                          type="tel"
                          id="phone"
                          className="form-control"
                          value=""
                          placeholder="Enter Your Number"
                        /> */}
                            {/* <PhoneInput
                              placeholder="Enter phone number"
                              value={phone}
                              onChange={setPhone}
                              // country={"PK"}
                              defaultCountry={country?.short_name}
                              // defaultCountry={country?.short_name}
                              countries={["SO", "NG", "UG", "US", "PK"]}
                              addInternationalOption={false}
                              className="form-control"
                              required={true}
                            /> */}
                            {countriesList?.list.length > 0 && (
                              <PhoneInput
                                placeholder="Enter phone number"
                                value={phone}
                                onChange={setPhone}
                                // country={"PK"}
                                country={country?.short_name.toLowerCase()}
                                // defaultCountry={country?.short_name}
                                onlyCountries={countriesList?.list?.map(
                                  (items) =>
                                    items.shortName.toString().toLowerCase()
                                )}
                                prefix="+"
                                addInternationalOption={false}
                                className=" phone-input"
                                // className="w-100"
                                required={true}
                                disabled={otpSent}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-12 mt-4 mx-auto">
                          <div className="d-grid">
                            <CustomButton
                              className="btn mb-4 p-2 text-center button-primary"
                              type="submit"
                              title="Get OTP"
                              isLoading={isLoading}
                            />
                            {/* <button
                              className="btn btn-primary mb-4 p-2"
                              type="submit"
                            >
                              Continue
                            </button> */}
                          </div>
                        </div>
                      </div>

                      {/* <div className="book-text p-2">
                        <p>Or continue using</p>
                      </div> */}
                      {/* <div className="d-flex align-items-center justify-content-center">
                   
                        <div className="mx-4">
                          <div className="book-text p-2">
                            <p>Already signed up using Facebook?</p>
                          </div>
                          <div className="text-center ">
                            <button className="btn btn-warning" type="button">
                              <i className="fa fa-facebook-f fb-icon"></i> Log
                              in with Facebook
                            </button>
                          </div>
                        </div>{" "}
                        <div className="mx-4">
                          <div className="book-text p-2">
                            <p>Already signed up using Google?</p>
                          </div>
                          <div className="text-center ">
                            <button className="btn btn-warning" type="button">
                              <i className="fa fa-google fb-icon"></i> Log in
                              with Google
                            </button>
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="mt-4">
                        <div className="book-text mt-4">
                          <p className="text-sm">
                            This page is protected by reCAPTCHA and is subject
                            to the Google <Link to="">Privacy Policy</Link> and{" "}
                            <Link to="">Terms of Service</Link>
                          </p>
                        </div>
                      </div> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PhoneLogin;
