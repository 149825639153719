import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./api";

export const ContactAPI = createApi({
  reducerPath: "contactAPI",
  baseQuery: baseQueryWithAuth,

  endpoints: (builder) => ({
    getContactAPI: builder.mutation({
      query: (body) => ({
        url: `/contacts`,
        method: "POST",
        body: body,
      }),

     
    }),
  }),
});

export const { useGetContactAPIMutation } = ContactAPI;
