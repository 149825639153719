import { configureStore } from "@reduxjs/toolkit";

import authSlice from "./slices/authSlice";
import sidebarSlice from "./slices/sidebarSlice";

import cabSlice from "./slices/locationSlice";
import rideSlice from "./slices/rideSlice";
import userLocationSlice from "./slices/locationSlice";
import vehicleCategorySlice from "./slices/vehicleCategorySlice";
import walletSlice from "./slices/walletSlice";
import settingSlice from "./slices/settingSlice";
import countrySlice from "./slices/countrySlice";
import { NotificationAPI } from "./services/notificationAPI";
import { setupListeners } from "@reduxjs/toolkit/query";
import { ContactAPI } from "./services/contactAPI";

const store = configureStore({
  reducer: {
    // products: Products,
    // cart: CartSlice,
    sidebar: sidebarSlice,
    auth: authSlice,
    location: userLocationSlice,
    cabs: cabSlice,
    rides: rideSlice,
    vehicleCategories: vehicleCategorySlice,
    wallet: walletSlice,
    settings: settingSlice,
    country: countrySlice,
    [NotificationAPI.reducerPath]: NotificationAPI.reducer,
    [ContactAPI.reducerPath]: ContactAPI.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(NotificationAPI.middleware,ContactAPI.middleware),
});
setupListeners(store.dispatch);

export default store;
