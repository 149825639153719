import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import CustomButton from "../../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { asyncRequestTopup } from "../../../redux/slices/walletSlice";
import { notifyFailure, notifySuccess } from "../../../components/toast";

function AddFundsModal({ show, closePopup }) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const wallet = useSelector((state) => state.wallet);
  const settings = useSelector((state) => state.settings);
  const { data } = settings;

  const { walletSettings } = data;
  console.log(walletSettings, "<<<<<");

  // const { currentRide } = rides;
  const [amount, setAmount] = useState(); // Initial time left in seconds
  const [isLoading, setIsLoading] = useState(false); // Initial time left in seconds
  const [success, setSuccess] = useState(false); // Initial time left in seconds

  const submitHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    dispatch(
      asyncRequestTopup({
        data: {
          // wallet: wallet.data._id,
          // type: "Credit",
          // paymentMethod: "Mobile Money",
          // currency: "USD",
          // description: "Topup by Passenger",
          amount,
        },
        callback: responseHandler,
      })
    );
  };

  const responseHandler = (res) => {
    if (res.success) {
      setSuccess(true);
      notifySuccess("Wallet topup request sent successfully!");
    } else {
      notifyFailure(res.message);
    }
    setIsLoading(false);
  };

  const amountValueHandler = (event) => {
    const { name, value } = event.target;
    setAmount(value);
  };

  useEffect(() => {
    if (walletSettings) {
      setAmount(walletSettings.minimumAmountToAdd);
    }
  }, []);

  console.log(amount, "amou");

  return (
    <Modal show={show} centered>
      <form onSubmit={submitHandler}>
        <div className="small-modal">
          <Modal.Header className="d-flex justify-content-between">
            <h5>Add Funds in Wallet</h5>
            {/* <h6 className="text-danger">{formattedTimeLeft}</h6> */}
          </Modal.Header>
          <Modal.Body>
            {/* Passenger Information */}
            <div className="mb-3">
              {/* <h6>Passenger Information:</h6> */}
              <div className="form-group my-4">
                <label className="mb-2" for="addFundsAmount">
                  Amount ( {auth?.userInfo?.country?.currency} ):
                </label>
                <input
                  type="number"
                  className="form-control"
                  placeholder={`Enter amount in ${auth?.userInfo?.country?.currency}`}
                  // value={amount? amount.maxValue: ""}
                  value={amount}
                  name="amount"
                  onChange={amountValueHandler}
                  disabled={success}
                  min={walletSettings ? walletSettings.minimumAmountToAdd : 0}
                  max={walletSettings ? walletSettings.maximumAmountToAdd : ""}
                />
              </div>

              {/* <button type="submit" className="btn btn-primary">
                Add Funds
              </button> */}
            </div>

            {success && (
              <div className="mb-3">
                <p>
                  <span className="fw-semibold">Note:</span> Please transfer the
                  amount{" "}
                  <span className="fw-semibold">
                    {auth?.userInfo?.country?.currency} {amount}
                  </span>{" "}
                  to
                  <span className="fw-semibold">
                    {" "}
                    account # +923362481489
                  </span>{" "}
                  and call the agent{" "}
                  <span className="fw-semibold">
                    ( Contact: +923362481489 )
                  </span>{" "}
                  to approve your topup request.
                </p>
              </div>
            )}
          </Modal.Body>{" "}
          <Modal.Footer>
            <CustomButton
              type="button"
              title="Close"
              className="btn btn-danger"
              // isLoading={isLoading}
              onClick={closePopup}
            />
            {!success && (
              <CustomButton
                type="submit"
                title="Submit"
                className="btn button-primary"
                isLoading={isLoading}
                // onClick={submitHandler}
              />
            )}
          </Modal.Footer>
        </div>
      </form>
    </Modal>
  );
}

export default AddFundsModal;
