import React from "react";
import { Link } from "react-router-dom";
import header from "../../assets/new-images/header-logo.png";

function Header() {
  return (
    <>
      {/* <div className="topbar-section">
        <nav
          className="navbar navbar-expand-lg navbar-light d-lg-block bg-dark"
          id="templatemo_nav_top"
        >
          <div className="container text-light">
            <div className="w-100 d-flex justify-content-end">
              <div className="list-inline">
                <ul>
                  <li>
                    <Link to="/login">log In</Link>
                  </li>
                  <li className="border-end  mx-3" />
                  <li>
                    <Link to="signup.html">Sign Up</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div> */}
      <div className="cabtify-header-section">
        <div className="axistify-header">
          <div className="container">
            <div className="row align-items-center ">
              <div className="col-md-6">
                <div className="tophead-text">
                  <span className="d-none d-md-block">
                    <a
                      href="mailto:info@cabtify.com"
                      className="text-white text-decoration-none"
                    >
                      <i className="fa fa-envelope mx-3 ico-col" />
                      info@cabtify.com
                    </a>
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="element">
                  <ul className="header-top-nav list-inline">
                    <li className="menu-item">
                      <span>
                        <a
                          href={`https://www.google.com/maps?q=${encodeURIComponent(
                            "8900 Penn Ave S Suite:309,Bloomington, MN 55431"
                          )}`}
                          target="_blank"
                          rel="noreferrer"
                          className="text-white text-decoration-none"
                        >
                          <i className="fa fa-map-marker mx-3 ico-col" />
                          8900 Penn Ave S Suite:309,Bloomington, MN 55431
                        </a>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="cabtify-header-img">
              <nav className="navbar navbar-expand-lg navbar-dark bg-dark-subtle">
                <div className="container">
                  {/* Logo */}
                  <Link to="/" className="navbar-brand">
                    <img src={header} alt="Logo" id="logsedf" />
                  </Link>
                  {/* Toggle button for small screens */}
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                  {/* Navbar links */}
                  <div
                    className="collapse navbar-collapse justify-content-center"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <Link className="nav-link" to="/">
                          Home
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/about-us">
                          About Us
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/services">
                          Our Services
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/contact-us">
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="collapse navbar-collapse justify-content-end"
                    id="navbarSupportedContent"
                  >
                    {/* Shop Dropdown */}
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <Link
                          className="nav-link btn btn-outline-light  rounded-1"
                          to="/login"
                        >
                          Book a Ride
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
