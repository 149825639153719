import React, { useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { useLocation } from "react-router-dom";

function PublicLayout({ children }) {
  const location = useLocation();
  useEffect(() => {
    // Select the element with the class name 'paraxdo-header-section'
    const element = document.querySelector(".paraxdo-header-section");

    // Scroll to the element if it exists
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [location.pathname]);

  return (
    <div className="main-container">
      <Header />
      <div className="content-area">{children}</div>
      <Footer />
    </div>
  );
}

export default PublicLayout;
