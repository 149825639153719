import React, { useEffect, useRef, useState } from "react";
import "react-phone-number-input/style.css";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncResendOtp,
  asyncSendOtp,
  asyncVerifyOtp,
  changeNumber,
} from "../../redux/slices/authSlice";
import { Link, useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";

const otpExpiryTime = 120;

function VerifyOtp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);
  const { otpSent, otpResent, phone } = auth;

  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [timeLeft, setTimeLeft] = useState(otpExpiryTime);

  console.log("phone==>", phone, otp);

  const isMounted = useRef(true);

  const submitHandler = async (e) => {
    console.log(e);
    e.preventDefault();
    setIsLoading(true);
    dispatch(
      asyncVerifyOtp({
        data: { phone, otp },
        callback: () => {
          setIsLoading(false);
        },
      })
    );
  };

  useEffect(() => {
    if (!auth.otpSent) navigate("/login");
    if (auth.userInfo) {
      if (auth.newRegister) navigate("/passenger-details");
      else navigate("/ride-now");
    }
  }, [auth]);

  // useEffect(() => {
  //   if (!otpSent) navigate("/login");
  // }, [otpSent]);

  const resendOtpHandler = () => {
    if (isMounted.current) {
      setIsLoading(true);
      dispatch(
        asyncResendOtp({
          callback: () => {
            setIsLoading(false);
          },
        })
      );
    }
  };

  useEffect(() => {
    if (otpResent) {
      setTimeLeft(otpExpiryTime);
      setOtp("");
    }
  }, [otpResent]);
  // const resendOtpSuccessHandler = () => {
  //   setTimeLeft(otpExpiryTime);
  // };
  useEffect(() => {
    isMounted.current = true;
    const interval = setInterval(() => {
      isMounted.current &&
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft > 0) {
            return prevTimeLeft - 1;
          }
          // clearInterval(interval) // Clear the interval when timeLeft reaches 0
          // setOtpExpired(true)
          return 0;
        });
    }, 1000);
    return () => {
      isMounted.current = false;
      clearInterval(interval);
    };
  }, []);

  return (
    <div className=" form-bg">
      {/* <!-- Section: Design Block --> */}
      <section>
        <div className="bookride-section">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-7 mb-lg-0 form-padding">
                <div className="card-bg">
                  <div className="card-body py-5 px-md-5">
                    <form onSubmit={submitHandler} className=" p-3">
                      <div className="text-center p-2 mb-3">
                        <h3>Verify OTP</h3>
                      </div>
                      <div className="col-12 col-md-8 mt-4 mx-auto">
                        {/* <input
                          type="tel"
                          id="phone"
                          className="form-control"
                          value=""
                          placeholder="Enter Your Number"
                        /> */}
                        <OtpInput
                          containerStyle=" justify-content-center gap-1 gap-md-2"
                          // className="form-control"
                          // inputType="number"
                          value={otp}
                          onChange={setOtp}
                          numInputs={6}
                          renderSeparator={<span>-</span>}
                          renderInput={(props) => (
                            <input
                              {...props}
                              className="otp-input"
                              disabled={timeLeft <= 0}
                            />
                          )}
                        />
                      </div>
                      <div className="col-12 col-md-8 mt-4 mx-auto">
                        {timeLeft > 0 ? (
                          <div className="d-grid">
                            {/* <button
                              className="btn btn-warning mb-4 p-2"
                              type="submit"
                            >
                              Verify OTP
                            </button> */}
                            <CustomButton
                              className="btn  mb-4 p-2 text-center button-primary"
                              type="submit"
                              title="Verify OTP"
                              isLoading={isLoading}
                            />
                          </div>
                        ) : (
                          <div className="d-grid">
                            {/* <button
                              onClick={resendOtpHandler}
                              className="btn btn-warning mb-4 p-2"
                              type="button"
                            >
                              Resend OTP
                            </button>   */}
                            <CustomButton
                              onClick={resendOtpHandler}
                              className="btn button-primary mb-4 p-2 text-center"
                              // type="submit"
                              title="Resend OTP"
                              isLoading={isLoading}
                            />
                          </div>
                        )}
                      </div>
                      <div className="book-text p-2">
                        {timeLeft > 0 ? (
                          <p className="text-center fw-bold color-primary">
                            OTP will expire in:{" "}
                            {timeLeft > 59
                              ? `0${Math.floor(timeLeft / 60)}:${
                                  timeLeft % 60 < 10
                                    ? `0${timeLeft % 60}`
                                    : timeLeft % 60
                                }`
                              : `00:${
                                  timeLeft < 10 ? `0${timeLeft}` : timeLeft
                                }`}
                          </p>
                        ) : (
                          <p className="text-center fw-bold text-danger">
                            OTP is expired, <br /> Please get a new one!
                          </p>
                        )}
                      </div>
                      <div className="col-12 col-md-8 mx-auto">
                        <p className="text-sm mb-0 text-center">
                          Your phone number is:{" "}
                          <span className="fw-bold">{phone}</span>
                          {"   "}
                          <Link
                            onClick={() => dispatch(changeNumber())}
                            to="/login"
                          >
                            Change
                          </Link>
                        </p>
                      </div>
                      {/* <div className="d-grid">
                        <button className="btn btn-warning mb-4 p-2" type="submit">
                          Continue
                        </button>
                      </div> */}
                      {/* <div className="mt-4">
                        <div className="book-text mt-4">
                          <p className="text-sm">
                            This page is protected by reCAPTCHA and is subject
                            to the Google <Link to="">Privacy Policy</Link> and{" "}
                            <Link to="">Terms of Service</Link>
                          </p>
                        </div>
                      </div> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default VerifyOtp;
