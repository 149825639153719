// Live Urls
export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000/api"
    : "https://cabtify-backend-790fd6a27e30.herokuapp.com/api";

export const SOCKET_BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000"
    : "https://cabtify-backend-790fd6a27e30.herokuapp.com";

// Testing Urls
// export const BASE_URL =
//   process.env.NODE_ENV === "development"
//     ?
//       "http://localhost:5000/api"
//       "https://cabtify-backend-test-ad9d1377d760.herokuapp.com/api";

// export const SOCKET_BASE_URL =
//   "https://cabtify-backend-790fd6a27e30.herokuapp.com";
// export const SOCKET_BASE_URL = "http://localhost:5000";

// export const BASE_URL =
//   process.env.NODE_ENV === "development"
//     ? // false
//       "http://localhost:5000/api"
//     : "https://cabtify-backend-790fd6a27e30.herokuapp.com/api";
// // : "https://api.infisom.com/api";

// ----------------------------------- Urls End---------------------------------
// Storage Bucket
export const BUCKET_BASE_URL =
  "https://pub-73015d2687184e93a7c705a5755bc877.r2.dev/";
export const BUCKET_PUBLIC_URL =
  "https://pub-68d352b545a748a29ccb074a3c7ef7ab.r2.dev/";
export const BUCKET_ENDPOINT =
  "https://5aa5d988e1bde0d278ab9f851dccfa85.r2.cloudflarestorage.com";
export const BUCKET_NAME = "cabtify";
export const BUCKET_REGION = "auto";
export const BUCKET_ACCESS_KEY_ID = "b66fb2c2c143b626a436ec1caa808431";
export const BUCKET_SECRET_KEY =
  "7b0b2f0e36f69ed390c425f173c334dab1ef8b0d4fa7f193555f059febf91cea";

export const GOOGLE_API_KEY = "AIzaSyAaG9SGlP7vxYTMS_U0bSH1aFL-wxU8l7M";
// export const GOOGLE_API_KEY = "AIzaSyDsOAHN_mtNP6SXpmc3EHFPNIPDhe2uv6Y";

export const driverPanelUrl = "https://drive.cabtify.com";

// SQUARE
export const SQUARE_APP_ID = "sandbox-sq0idb-imc7Ui-KiW_RZaRkInrWqw";
export const SQUARE_ACCESS_TOKEN =
  "EAAAl2z4g_GBGlZoYYFGXkVcxlqB_PYh-VF2u2l-FvVu2tPvX2Z3Ox1_EnHXLSUD";
export const SQUARE_LOCATION_ID = "LJQBD4G2PYSPP";
