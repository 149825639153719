import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  DirectionsService,
  DirectionsRenderer,
  useLoadScript,
  MarkerF,
  InfoWindowF,
} from "@react-google-maps/api";
import { GOOGLE_API_KEY } from "../../constants";
import { MdOutlineGpsFixed } from "react-icons/md";

const containerStyle = {
  width: "100%",
  height: "500px",
};

// const center = {
//   lat: 0, // Set your initial latitude
//   lng: 0, // Set your initial longitude
// };
const libraries = ["places"];

const MapComponent = ({
  pickup,
  dropoff,
  duration,
  setDuration,
  distance,
  setDistance,
  fareDetails,
  directions,
  setDirections,

  driverLocation,
  // setFare,
}) => {
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [zoom, setZoom] = useState(15);
  const [userLocation, setUserLocation] = useState({ lat: 0, lng: 0 });
  const [openPopup, setOpenPopup] = useState(false);
  // const location = useSelector((state) => state.location);

  // console.log("dist==>", distance, duration, directions);
  //   const onMapClick = (event) => {
  //     //     if (!pickup) {
  //     //       setPickup({ lat: event.latLng.lat(), lng: event.latLng.lng() });
  //     //     } else if (!dropoff) {
  //     //       setDropoff({ lat: event.latLng.lat(), lng: event.latLng.lng() });
  //     //       calculateDirections();
  //     //     }
  //   };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: libraries,
  });

  const calculateDirections = () => {
    setDirections(null);
    if (pickup && dropoff) {
      const directionsService = new window.google.maps.DirectionsService();

      directionsService.route(
        {
          origin: {
            lat: pickup.location.coordinates[0],
            lng: pickup.location.coordinates[1],
          },
          destination: {
            lat: dropoff.location.coordinates[0],
            lng: dropoff.location.coordinates[1],
          },
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            // Clear any existing directions from the map

            // Set the new directions

            setDirections(result);
            const leg = result.routes[0].legs[0];
            setDistance(leg.distance.text);
            setDuration(leg.duration.text);

            // const bounds = new window.google.maps.LatLngBounds();
            // bounds.extend(pickup);
            // bounds.extend(dropoff);
            // setCenter(bounds.getCenter());
          }
        }
      );
    }
  };

  // Function to navigate to the current location
  const navigateToCurrentLocation = () => {
    setCenter({ lat: userLocation.lat, lng: userLocation.lng });
    // map.zoom = 15;
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
          setCenter({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (isLoaded && pickup && dropoff) {
      calculateDirections();
    }
  }, [isLoaded, pickup, dropoff]);

  const isLargeScreen = window.innerWidth >= 1024; // Define your breakpoint for large screens

  //   useEffect(() => {
  //     if (isLoaded && pickup && dropoff) {
  //       const bounds = new window.google.maps.LatLngBounds();
  //       bounds.extend(pickup);
  //       bounds.extend(dropoff);
  //       setCenter(bounds.getCenter());

  //       const map = document.querySelector(".gm-iv-container");
  //       const mapWidth = map.clientWidth;
  //       const mapHeight = map.clientHeight;

  //       const padding = {
  //         top: mapHeight * 0.2, // Adjust these padding values as needed
  //         bottom: mapHeight * 0.2,
  //         left: mapWidth * 0.2,
  //         right: mapWidth * 0.2,
  //       };

  //       const zoom = getZoomLevel(
  //         bounds,
  //         { width: mapWidth, height: mapHeight },
  //         padding
  //       );
  //       setZoom(zoom);
  //     }
  //   }, [isLoaded, pickup, dropoff]);

  //   const getZoomLevel = (bounds, mapDimensions, padding) => {
  //     const WORLD_DIM = { height: 256, width: 256 };
  //     const ZOOM_MAX = 21;
  //     const angle = bounds.getNorthEast().lng() - bounds.getSouthWest().lng();
  //     const zoom = Math.min(
  //       Math.floor(
  //         Math.log((mapDimensions.width * 360) / angle / WORLD_DIM.width) /
  //           Math.LN2
  //       ),
  //       Math.floor(
  //         Math.log((mapDimensions.height * 180) / angle / WORLD_DIM.height) /
  //           Math.LN2
  //       ),
  //       ZOOM_MAX
  //     );
  //     return zoom - (padding ? 1 : 0);
  //   };

  //   const mapCenter = userLocation || { lat: 0, lng: 0 }; // Use userLocation if available, otherwise use { lat: 0, lng: 0 }

  useEffect(() => {
    if (pickup) {
      // setDirections(null);

      setCenter({
        lat: pickup.location.coordinates[0],
        lng: pickup.location.coordinates[1],
      });
    }
  }, [pickup]);

  useEffect(() => {         
    if (dropoff) {                         
      // setDirections(null);

      setCenter({
        lat: dropoff.location.coordinates[0],
        lng: dropoff.location.coordinates[1],               
      });
    }
  }, [dropoff]);
          
  console.log("driver location==>", driverLocation);        
  if (!isLoaded) {
    return null; // Don't render the component until libraries are loaded
  }
  return (      
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      //   onClick={onMapClick}          
      options={{
        // scrollwheel: !isLargeScreen, // Disable default scroll zoom on small screens
        gestureHandling: isLargeScreen ? "cooperative" : "greedy", // Enable control + scroll zoom on large screens
        zoomControlOptions: {
          position: window.google.maps.ControlPosition.LEFT_TOP,
        },
        streetViewControlOptions: {
          position: window.google.maps.ControlPosition.LEFT_TOP,
        },
        fullscreenControlOptions: {             
          position: window.google.maps.ControlPosition.BOTTOM_RIGHT,
        },
      }}
    >                      
      {!directions && pickup && (
        <Marker
          position={{       
            lat: pickup.location.coordinates[0],
            lng: pickup.location.coordinates[1],
          }}
        />
      )}                  
      {!directions && dropoff && (     
        <Marker
          position={{                    
            lat: dropoff.location.coordinates[0],
            lng: dropoff.location.coordinates[1],                     
          }}
        />
      )}

      {directions && <DirectionsRenderer directions={directions} />}

      {directions && (            
        <div
          style={{
            position: "absolute",             
            background: "#198754",
            color: "#fff",
            padding: "10px",         
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "5px", 
            right: 10,
            top: 10,   
            // left: `${selectedMarker.lng + 0.001}deg`,
            // top: `${selectedMarker.lat + 0.001}deg`,
            zIndex: 1000,
          }}
        >
          <p className="mb-0">Estimate:</p>  
          {fareDetails && (
            <p className="mb-0">
              {fareDetails.currency} {fareDetails.totalFare}
            </p>
          )}
          <p className="mb-0">
            {distance} / {duration}
          </p>
        </div>
      )}

      <button              
        style={{
          position: "absolute",       
          // background: "#198754",
          background: "#fff",
          padding: "8px",
          border: "none",
          boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.3)",
          // borderRadius: "5px",
          right: 10,
          top: 10,         
          // left: `${selectedMarker.lng + 0.001}deg`,
          // top: `${selectedMarker.lat + 0.001}deg`,
          zIndex: 1000,
        }}
        onClick={navigateToCurrentLocation} 
      >
        {/* <img src="/assets/images/my-location.png" width={25} height={25} /> */}
        <MdOutlineGpsFixed size={25} />                   
      </button>

      <MarkerF
        position={{
          lat: userLocation.lat,
          lng: userLocation.lng,
        }}
        icon={{
          url: "/assets/gifs/blue-dot.svg",
          scaledSize: new window.google.maps.Size(50, 50), // Adjust size as needed
        }}
        onMouseOver={() => setOpenPopup(true)}
        onMouseOut={() => setOpenPopup(false)}
        onClick={() => setOpenPopup(true)}     
      >
        {openPopup && (
          <InfoWindowF
          // position={{
          //   lat: location.coordinates[0],
          //   lng: location.coordinates[1],
          // }}
          // onCloseClick={() => setOpenPopup(false)}
          >
            <div>
              <h6>You are here!</h6>
            </div>
          </InfoWindowF>
        )}
      </MarkerF>

      {driverLocation && (
        <MarkerF 
          position={{
            lat: driverLocation.coordinates[0] + 0.0005,
            lng: driverLocation.coordinates[1] + 0.0005,
          }}
          icon={{    
            url: "/assets/images/car-marker.png",
            scaledSize: new window.google.maps.Size(50, 50), // Adjust size as needed
          }}
          onMouseOver={() => setOpenPopup(true)}
          onMouseOut={() => setOpenPopup(false)}        
          onClick={() => setOpenPopup(true)}     
        >
          {openPopup && (
            <InfoWindowF    
            // position={{
            //   lat: location.coordinates[0],
            //   lng: location.coordinates[1],
            // }}
            // onCloseClick={() => setOpenPopup(false)}
            >
              <div>
                <h6>Your Taxi is here!</h6>
              </div>   
            </InfoWindowF>
          )}
        </MarkerF>
      )}
    </GoogleMap>
  );
};

export default MapComponent;
