import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosPrivate, { axiosPublic } from "../../services/api";
import toast from "react-hot-toast";

export const asyncGetRides = createAsyncThunk(
  "getRides",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.get("/rides/passenger");

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncGetCurrentRide = createAsyncThunk(
  "getCurrentRide",
  async (obj, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.get(
        "/v2/rides/getPassengerCurrentRide"
      );

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncGetRideDetails = createAsyncThunk(
  "getRideDetails",
  async (id, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.get(`/rides/${id}`);

      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);

      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const asyncGetChat = createAsyncThunk(
  "getChat",
  async (_id, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axiosPrivate.get(`/rides/${_id}/messages`);
      return fulfillWithValue(response.data);
    } catch (error) {
      console.log("err==>", error.response.data.message);
      toast.error(error.response.data.message);
      throw rejectWithValue(error.response.data.message);
    }
  }
);

const rideSlice = createSlice({
  name: "rides",
  initialState: {
    isLoading: false,
    // userInfo: localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : null,
    list: [],
    isError: false,
    add: {},
    update: {},
    currentRide: null,
    rideDetails: {
      ride: null,
    },
    chat: [],
    driverLocation: null,
  },
  extraReducers: (builder) => {
    // Cases for Get Rides
    builder.addCase(asyncGetRides.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetRides.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetRides.rejected, (state, action) => {
      // console.log("Error", action);
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    // Cases for current ride
    builder.addCase(asyncGetCurrentRide.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetCurrentRide.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentRide = action.payload.ride ? action.payload.ride : null;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetCurrentRide.rejected, (state, action) => {
      // console.log("Error", action);
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    // Cases for ride details
    builder.addCase(asyncGetRideDetails.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetRideDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.rideDetails = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetRideDetails.rejected, (state, action) => {
      // console.log("Error", action);
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });

    // chats Section
    builder.addCase(asyncGetChat.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(asyncGetChat.fulfilled, (state, action) => {
      state.isLoading = false;
      state.chat = action.payload;
      state.isError = false;
      state.message = null;
    });
    builder.addCase(asyncGetChat.rejected, (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.isError = true;
    });
  },
  reducers: {
    setRideDetails(state, action) {
      state.currentRide = action.payload;
      if (action.payload) {
        state.hasActiveRide = true;
      } else {
        state.hasActiveRide = false;
      }
    },

    updateDriverLocation(state, action) {
      state.driverLocation = action.payload;
    },
    updateRideDetails(state, action) {
      state.currentRide.route = action.payload.route;
      state.currentRide.rideActions = action.payload.rideActions;
      state.currentRide.status = action.payload.status;
    },
    updateCurrentRideActions(state, action) {
      state.currentRide.rideActions = action.payload;
    },
    cancelRide(state, action) {
      state.currentRide = null;
    },
    messageReceived(state, action) {
      state.chat = [...state.chat, action.payload];
    },       
  },
});

export const {
  setRideDetails,
  updateDriverLocation,
  updateRideDetails,
  updateCurrentRideActions,
  cancelRide,
  messageReceived,
} = rideSlice.actions;
export default rideSlice.reducer;
