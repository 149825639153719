import React from "react";

const ErrorOverlay = ({ errorMessage, onClose }) => {
  return (
    <div
      className="modal show fullscreen-error-overlay"
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog error-container" role="document">
        <p>{errorMessage}</p>
      </div>
    </div>
  );
};

export default ErrorOverlay;
