import React from "react";
import image from "../../assets/new-images/image.png";
import mession from "../../assets/new-images/mession.png";
import { Link } from "react-router-dom";

function About() {
  return (
    <>
      <div className="pagesbanner-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="text-center bannnertext">
                <h1>About Us</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="abt-section">
        <div className="container">
          <div className="row align-items-center justify-content-around">
            <div className="col-lg-5 col-md-12 col-sm-12">
              <div className="ratio ratio-4x3 shadow-sm rounded-5">
                <img src={image} alt="" />
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12">
              <div className="text-center abttext">
                <h2>About Us</h2>
                <p>
                  Cabtify is committed to providing top-quality, dependable
                  transportation across Bloomington, MN, and surrounding areas.
                  Whether you are heading to the airport, attending a corporate
                  event, or celebrating a special occasion, Cabtify delivers
                  reliable service with a focus on comfort, safety, and
                  convenience. We believe in making every ride seamless and
                  enjoyable, tailored to meet the unique needs of each client.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ser-section">
        <div className="container">
          <div className="row align-items-center justify-content-around">
            <div className="col-lg-5 col-md-12 col-sm-12">
              <div className="abttext">
                <h3>Mission</h3>
                <p>
                  Our mission at Cabtify is to provide reliable, high-quality
                  transportation that consistently exceeds expectations, making
                  us a trusted choice for car service in Bloomington, MN.
                </p>
                <h3>Vision</h3>
                <p>
                  Cabtify aims to become a leader in the transportation industry
                  by setting standards of excellence, embracing innovation, and
                  fostering environmental responsibility. We envision a world
                  where premium, reliable, and sustainable transportation is
                  accessible to all.
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12">
              <div className="ratio ratio-4x3 shadow-sm rounded-5">
                <img src={mession} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cont-section mb-2 mt-2">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="con-text">
                <h2>Contact Us</h2>
                <p>
                  Book your next ride with Cabtify, and experience
                  transportation that puts your needs first.
                </p>
                <Link to="/contact-us" className="btn btn-light rounded-1 px-5">
                  Contact Us Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
