import React from 'react'
import { Button, Modal } from "react-bootstrap";
import CustomButton from '../CustomButton';
import Chat from '../../pages/ChatPage/inbox';
import { MdCancel } from "react-icons/md";

function ChatPopup({show, setChatOpen}) {
  const handleClose = () => setChatOpen(false);

  console.log(show, setChatOpen, "setChatOpen")
  return (
    <Modal show={show} centered size="lg" onHide={handleClose} >
    <div className="small-modal">
      <Modal.Header className="d-flex justify-content-between align-items-center">
        <p className="m-0 p-0" style={{ fontWeight: "bold" }}>
          Chat
        </p>
        <div className="" style={{ fontWeight: "bold", fontSize: "16px" }} onClick={handleClose}>
          {" "}
          <MdCancel />{" "}
        </div>
      </Modal.Header>
      <Modal.Body>
        {/* Passenger Information */}
        <Chat />
      </Modal.Body>{" "}
    </div>
  </Modal>
  )
}

export default ChatPopup