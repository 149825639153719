import React, { useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import servicesimg1 from "../../assets/new-images/servicesimg-1.png";
import servicesimg2 from "../../assets/new-images/servicesimg-2.png";
import servicesimg3 from "../../assets/new-images/servicesimg-3.png";
import servicesimg4 from "../../assets/new-images/servicesimg-4.png";
import servicesimg5 from "../../assets/new-images/servicesimg-5.png";
import sericon from "../../assets/new-images/sericon.png";
import sericon2 from "../../assets/new-images/sericon-2.png";
import sericon3 from "../../assets/new-images/sericon-3.png";
import sericon4 from "../../assets/new-images/sericon-4.png";

const TermsService = () => {
  useEffect(() => {
    // Select the element with the class name 'paraxdo-header-section'
    const element = document.querySelector(".paraxdo-header-section");

    // Scroll to the element if it exists
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <div className="pagesbanner-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="text-center bannnertext">
                <h1>Our Services </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="abt-section">
        <div className="container">
          <div className="row align-items-center mb-5">
            <div className="col-lg-4 col-md-5 col-sm-12">
              <div className="ratio ratio-4x3 shadow-sm rounded-5 mb-3">
                <img src={servicesimg1} alt="" />
              </div>
            </div>
            <div className="col-lg-8 col-md-7 col-sm-12">
              <div className="servicestext">
                <div className="iconimgserv text-start mb-3">
                  <img src={sericon} alt="" />
                </div>
                <h3 className="mb-2">Airport Transfers</h3>
                <p>
                  Start your travels on the right note with our punctual and
                  comfortable airport transfer services. Cabtify serves
                  Minneapolis–Saint Paul International Airport (MSP) and other
                  major airports, ensuring a smooth experience from pick-up to
                  drop-off.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center mb-5">
            <div className="col-lg-4 col-md-5 col-sm-12">
              <div className="ratio ratio-4x3 shadow-sm rounded-5 mb-3">
                <img src={servicesimg2} alt="" />
              </div>
            </div>
            <div className="col-lg-8 col-md-7 col-sm-12">
              <div className="servicestext">
                <div className="iconimgserv text-start mb-3">
                  <img src={sericon2} alt="" />
                </div>
                <h3 className="mb-2">Corporate Transportation</h3>
                <p>
                  Our executive car service is ideal for professionals needing
                  efficient and dependable transportation. Cabtify prioritizes
                  discretion, comfort, and timeliness, making sure you arrive at
                  every meeting relaxed and prepared.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center mb-5">
            <div className="col-lg-4 col-md-5 col-sm-12">
              <div className="ratio ratio-4x3 shadow-sm rounded-5 mb-3">
                <img src={servicesimg3} alt="" />
              </div>
            </div>
            <div className="col-lg-8 col-md-7 col-sm-12">
              <div className="servicestext">
                <div className="iconimgserv text-start mb-3">
                  <img src={sericon3} alt="" />
                </div>
                <h3 className="mb-2">Special Events &amp; Occasions</h3>
                <p>
                  From weddings to anniversaries and proms, let Cabtify make
                  your celebrations unforgettable. Our luxury service adds a
                  touch of class, perfect for any special event.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center mb-5">
            <div className="col-lg-4 col-md-5 col-sm-12">
              <div className="ratio ratio-4x3 shadow-sm rounded-5 mb-3">
                <img src={servicesimg4} alt="" />
              </div>
            </div>
            <div className="col-lg-8 col-md-7 col-sm-12">
              <div className="servicestext">
                <div className="iconimgserv text-start mb-3">
                  <img src={sericon4} alt="" />
                </div>
                <h3 className="mb-2">Event Transportation</h3>
                <p>
                  Attending a concert, sports game, or festival? Skip the
                  traffic and parking hassles by choosing Cabtify for smooth,
                  stress-free event transportation.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-5 col-sm-12">
              <div className="ratio ratio-4x3 shadow-sm rounded-5 mb-3">
                <img src={servicesimg5} alt="" />
              </div>
            </div>
            <div className="col-lg-8 col-md-7 col-sm-12">
              <div className="servicestext">
                <div className="iconimgserv text-start mb-3">
                  <img src={sericon4} alt="" />
                </div>
                <h3 className="mb-2">Hourly Service</h3>
                <p>
                  Enjoy the flexibility of having a driver and vehicle at your
                  service for multiple stops or longer trips. Our hourly service
                  is convenient for shopping, city tours, or business errands.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsService;
