import { io } from "socket.io-client";
import { BASE_URL } from "./api";
import { SOCKET_BASE_URL } from "../constants";
// import handleIDB from '../utils/handle-indexed-db'

const driverId = localStorage.getItem("cabtifyDriverInfo")
  ? JSON.parse(localStorage.getItem("cabtifyDriverInfo"))._id
  : "";

console.log("driverId==>", driverId);

// const getUserInfo = () =>
//   new Promise((resolve) => {
//     try {
//       resolve(handleIDB.getVal({ model: 'Auth', _id: 'userData' }))
//     } catch (err) {
//       console.error(err)
//       resolve({})
//     }
//   })

// const userid = (await getUserInfo())?._id || ''
// const socket = socketIOClient.connect(BASE_URL)
const socket = io(SOCKET_BASE_URL, {
  query: { userId: driverId, userType: "Driver" },
  transports: ["polling"],
  // perMessageDeflate: false,
  // transports: ['websocket'],
  // transports: ['websocket', 'polling'],
  // withCredentials: true,
  // extraHeaders: {
  //   // pooling only
  //   userid,
  // },
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 10000,
  reconnectionAttempts: Infinity,
  // reconnectionAttempts: 1,
});

export default socket;
